
import { Component, Vue } from 'vue-property-decorator'
import { FaceComBlack } from '@/types/blackList'

@Component
export default class Detail extends Vue {
    private info: FaceComBlack = {
      face: {
        resourceList: [],
        projectName: '',
        collectTime: '',
        deviceNumber: ''
      },
      black: {
        blackUserName: '',
        gender: '',
        remark: '',
        resourceList: []
      }
    }

    created () {
      this.getDetail()
    }

    getDetail () {
      this.$axios.get(this.$apis.blackList.selectCameraFaceDetail, {
        faceId: this.$route.params.id
      }).then(res => {
        this.info = res || {}
      })
    }
}
